// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stock-data-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.stock-data-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.stock-data-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.stock-data-modal-header {
  margin-top: 0;
}

.stock-data-modal-button {
  margin-right: 10px;
}

.stock-data-modal-select {
  margin-top: 10px;
  margin-bottom: 10px;
}

.error-message {
  color: red;
}

.stock-data-scrollable {
  max-height: 400px; /* Set the maximum height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
}

.stock-data-list {
  list-style-type: none;
  padding: 0;
}

.stock-data-list li {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/user_account_page/StockData/StockData.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB,EAAE,mDAAmD;EACtE,gBAAgB,EAAE,8BAA8B;AAClD;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".stock-data-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.stock-data-modal-content {\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 10px;\n  width: 80%;\n  max-height: 80%;\n  overflow-y: auto;\n}\n\n.stock-data-modal-close {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.stock-data-modal-header {\n  margin-top: 0;\n}\n\n.stock-data-modal-button {\n  margin-right: 10px;\n}\n\n.stock-data-modal-select {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.error-message {\n  color: red;\n}\n\n.stock-data-scrollable {\n  max-height: 400px; /* Set the maximum height for the scrollable area */\n  overflow-y: auto; /* Enable vertical scrolling */\n}\n\n.stock-data-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.stock-data-list li {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
