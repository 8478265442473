// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accounts-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .account-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  
  .account-item span {
    flex: 1 1;
  }
  
  .account-item input[type="checkbox"] {
    margin-left: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/user_account_page/FinancialRecordsModal/ExchangeLinkTokenModal/ExchangeLinkTokenModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".accounts-list {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px;\n  }\n  \n  .account-item {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 0;\n    border-bottom: 1px solid #ccc;\n  }\n  \n  .account-item span {\n    flex: 1;\n  }\n  \n  .account-item input[type=\"checkbox\"] {\n    margin-left: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
