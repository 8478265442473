// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spending-records-edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spending-records-edit-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .spending-records-edit-modal-content h2 {
    margin-bottom: 15px;
  }
  
  .spending-records-edit-modal-content label {
    display: block;
    margin: 10px 0;
  }
  
  .spending-records-edit-modal-content input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .spending-records-edit-modal-content button {
    margin: 10px 5px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .spending-records-edit-modal-content button:hover {
    background-color: #ddd;
  }
  
  .spending-records-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/user_account_page/FinancialRecordsModal/EditModal/EditModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,eAAe;EACjB","sourcesContent":[".spending-records-edit-modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .spending-records-edit-modal-content {\n    background: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 400px;\n    width: 100%;\n    text-align: center;\n  }\n  \n  .spending-records-edit-modal-content h2 {\n    margin-bottom: 15px;\n  }\n  \n  .spending-records-edit-modal-content label {\n    display: block;\n    margin: 10px 0;\n  }\n  \n  .spending-records-edit-modal-content input {\n    width: 100%;\n    padding: 8px;\n    margin-top: 5px;\n    border-radius: 4px;\n    border: 1px solid #ddd;\n  }\n  \n  .spending-records-edit-modal-content button {\n    margin: 10px 5px;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .spending-records-edit-modal-content button:hover {\n    background-color: #ddd;\n  }\n  \n  .spending-records-close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 18px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
