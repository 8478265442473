// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meetings-calendar-container {
  position: relative;
}

.meeting-dot {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
  cursor: pointer;
}

.react-calendar {
  width: 100%;
  height: 100%;
}

.meeting-details-popup {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
}

.react-calendar__tile--active .meeting-dot {
  background-color: #28a745;
}
`, "",{"version":3,"sources":["webpack://./src/components/user_account_page/Meetings/MeetingsCalendar/MeetingsCalendar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,aAAa;EACb,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".meetings-calendar-container {\n  position: relative;\n}\n\n.meeting-dot {\n  width: 10px;\n  height: 10px;\n  background-color: #007bff;\n  border-radius: 50%;\n  display: inline-block;\n  margin: 2px;\n  cursor: pointer;\n}\n\n.react-calendar {\n  width: 100%;\n  height: 100%;\n}\n\n.meeting-details-popup {\n  position: absolute;\n  background: white;\n  border: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 5px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  width: 200px;\n}\n\n.react-calendar__tile--active .meeting-dot {\n  background-color: #28a745;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
